<template>
  <div class="camera" v-show="showId === 'video' || showId === 'grow'">
    <div class="eq-title">{{ cameraTitle }}</div>
    <div class="videos">
      <iframe
        frameborder="0"
        scrolling="no"
        :src="videoUrl"
        name="video"
        style="width: 100%; height: 100%"
      ></iframe>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    videoUrl: {
      type: String,
      default: "",
    },
    cameraTitle: {
      type: String,
      default: "",
    },
    showId: {
      type: String,
      default: "",
    },
  },

  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      uploadImgs: (state) => state.app.uploadImgs,
      farmInfo: (state) => state.user.farmInfo,
    }),
  },
};
</script>

<style lang="less" scoped>
.camera {
  height: 353px;
  width: 402px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top: 6px solid #125397;
  background-color: #0f243d;
  display: flex;
  padding: 22px 10px;
  flex-direction: column;
  position: absolute;
  top: 106px;

  left: 78px;
  z-index: 1999;
  transition: all 0.5s;
  .eq-title {
    color: #3e90e5;
    font-size: 16px;
    margin-bottom: 15px;
    text-align: center;
  }
  .videos {
    width: 100%;
    height: 100%;
    video {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1999;
    }
  }
}
</style>
